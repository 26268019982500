import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppProvider from "./AppProvider";
import SignInPage from "./pages/signin/SignInPage";
import MainPage from "./pages/main/MainPage";
import SignUpPage from "./pages/signup/SignUpPage";
import AccessDeniedPage from "./pages/denied";
import Queue from "nq";
import RedirectPage from "./pages/RedirectPage";
import TestPage from "./pages/TestPage";
import TenantPage from "./pages/TenantPage";
import ForgotPage from "./pages/forgot/ForgotPage";
import ResetPage from "./pages/reset/ResetPage";
import ResetExpired from "./pages/reset/ResetExpired";

const queueUrl = process.env.REACT_APP_QUEUE_URL;
// const queueUrl = "/queue";
// const queueUrl =
//   process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_QUEUE_URL
//     : "/queue";

Queue.setUrl(queueUrl);

const applicationId = process.env.REACT_APP_QUEUE_APPLICATION_ID;

console.log("queue", queueUrl);

Queue.setUrl(queueUrl);
Queue.setApplicationId(applicationId);

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/test" element={<TestPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signin/:masterKey" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/redirect/:id" element={<RedirectPage />} />
          <Route path="/forgot" element={<ForgotPage />} />
          <Route path="/app" element={<TenantPage />} />
          <Route path="/*" element={<MainPage />} />
          <Route path={"/denied"} element={<AccessDeniedPage />} />
          <Route path="/reset/:token" element={<ResetPage />} />
          <Route path={"/expired"} element={<ResetExpired />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
